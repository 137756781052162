<template>
  <v-container
      fill-height
      fluid
      grid-list-xl
  >
    <v-layout wrap>
      <div class="flex-display flex-column flex-justify-between mb-3 w-100" >
        <div class="row">
          <div class="col-md-4">
            <introduction :user="therapist">
              <!-- Profile Image -->

              <template slot="profile-image">
                <profile-picture :value="therapist.profile_picture"
                                 :user_id="user_id"
                                 v-on:updateProfileImage="getTherapistProfile">
                </profile-picture>
              </template>

              <!-- Therapist Name -->

              <template slot="therapist-name">
                <therapist-name :first_name="therapist.first_name"
                                :last_name="therapist.last_name"
                                v-on:updateProfile="updateProfile">

                </therapist-name>
              </template>

              <!-- Therapist Designation -->

              <template slot="therapist-designation">
                <therapist-designation :designation="therapist.designation"
                                       v-on:updateProfile="updateProfile">
                </therapist-designation>
              </template>

              <!-- Therapist Title -->

              <template slot="therapist-title">
                <therapist-title :title="therapist.title"
                                 v-on:updateProfile="updateProfile">
                </therapist-title>
              </template>

              <!-- Therapist Gender -->

              <template slot="therapist-gender">
                <therapist-gender :gender="therapist.gender"
                                  v-on:updateProfile="updateProfile">
                </therapist-gender>
              </template>

              <template slot="setting-toggles">

                <!-- Therapist Approve -->

                <div class="toggle-switch mb-2">
                  <template v-if="therapist.approval_status === 'requires_approval'">
                    <span class="text-capitalize highlighted">{{ therapist.approval_status.replace('_', ' ') }}</span>
                    <span class="btn-approve mb-2" @click="approveUser">Approve</span>
                  </template>
                  <template v-else>
                    <label class="switch-label">Active</label>
                    <label class="switch">
                      <input type="checkbox" name="is_active" v-model="user_status" true-value="1" false-value="0"
                             v-on:change="toggleUpdated">
                      <span class="slider round"></span>
                    </label>
                  </template>
                </div>


                <div class="toggle-switch mb-2">

                  <label class="switch-label">Is Psychiatrist</label>
                  <label class="switch">
                    <input type="checkbox" name="is_psychiatrist" v-model="therapist.is_physiatrist" true-value="1"
                           false-value="0" v-on:change="updatePsychiatristStatus">
                    <span class="slider round"></span>
                  </label>
                </div>

                <!-- Case Load Full-->
                <div class="toggle-switch mb-2">
                  <label class="switch-label">Caseload full</label>
                  <label class="switch">
                    <input type="checkbox" name="temp_leave" v-model="therapist.case_load_full" true-value="true" false-value="false" v-on:change="caseLoadFull">
                    <span class="slider round"></span>
                  </label>
                </div>

                <!-- FTE -->

                <div class="toggle-switch mb-2">
                  <label class="switch-label">Is FTE</label>
                  <label class="switch">
                    <input type="checkbox" name="is_fte" v-model="therapist.fte" true-value="1" false-value="0"
                           v-on:change="updateFteStatus">
                    <span class="slider round"></span>
                  </label>
                </div>

                <!-- Therapist Leave -->

                <div class="toggle-switch mt-2">
                  <label class="switch-label">On Leave</label>
                  <div class="flex-row flex-display justify-content-end align-items-center">
                    <p class="m-1 pl-2" v-if="therapistLeave">{{ therapistLeave.start_date }} -
                      {{ therapistLeave.end_date }}</p>
                    <img src="/icons/start_a_conversation_ic.svg" alt="addLeave-icon" class="ml-2 cursor-pointer"
                         @click="showTherapistAddLeaveDialog">
                  </div>
                </div>

              </template>

              <template slot="fee-percentage">
                <!-- 30 min payout -->
                <therapist-payout-fee :therapist-id="therapist.id"
                               class="mb-2"
                               :fee="parseFloat(therapist.therapy_fee_30_min)"
                               :name="'30 min payout'"
                               v-on:feeUpdated="updateFee"
                ></therapist-payout-fee>
                <!-- 50 min payout -->

                <therapist-payout-fee :therapist-id="therapist.id"
                               class="mb-2"
                               :fee="parseFloat(therapist.therapy_fee_50_min)"
                               :name="'50 min payout'"
                               v-on:feeUpdated="updateFee"
                ></therapist-payout-fee>
              </template>
              <!-- Xero Contact -->
              <template slot="xero-contact">
                <therapist-xero-contact class="mb-2"
                                        :therapistId="therapist.id"
                                        :xeroContact="therapist.xero_contact"
                                        v-on:xeroContactUpdated="updateXeroContact"
                ></therapist-xero-contact>
              </template>
              <template slot="invoice-data">
                  <therapist-standing-payment-id class="mb-2"
                                                   :therapistId="therapist.id"
                                                   :paymentId="therapist.standing_payment_id"
                                                   v-on:standingPaymentIdUpdated="standingPaymentIdUpdated">
                  </therapist-standing-payment-id>
                  <therapist-latest-invoice class="mb-2"
                                              :therapistId="therapist.id"
                                              :latestInvoice="therapist.latest_invoice"
                                              v-on:latestInvoiceUpdated="latestInvoiceUpdated">
                  </therapist-latest-invoice>
              </template>

              <!-- Short Bio -->

              <template slot="therapist-bio">
                <therapist-bio :bio="therapist.short_bio"
                               class="mb-2"
                               v-on:updateProfile="updateProfile">
                </therapist-bio>
              </template>

              <!-- Therapist Email -->

              <template slot="email">
                <therapist-email :email="therapist.email_address"
                                 class="mb-2"
                                 v-on:updateProfile="updateProfile"
                ></therapist-email>
              </template>

              <!-- Therapist Video -->

              <template slot="therapistVideo">
                <therapist-video :user_id="user_id" :video="therapist.video_url"
                                 v-on:update_therapist_profile="updateTherapistProfile"></therapist-video>
              </template>

              <template slot="therapistVideoDuration" v-if="therapist.video_url">
                <therapist-video-duration :user_id="user_id"
                                          :duration="therapist.video_duration"></therapist-video-duration>
              </template>

              <template slot="therapistVideoStatus" v-if="therapist.video_url">
                <div class="toggle-switch">
                  <label class="switch-label">Welcome video active</label>
                  <label class="switch">
                    <input type="checkbox" name="is_active" v-model="therapist.is_video_active" true-value="1"
                           false-value="0" v-on:change="updateWelcomeVideoStatus">
                    <span class="slider round"></span>
                  </label>
                </div>
              </template>

                <template slot="phone">
                    <p>
                        <img alt="MOT" class="mr-3" src="/icons/mobile_number_ic.svg"/>
                        <a :href="'tel:'+therapist.phone_number">{{ therapist.phone_number }}</a>
                    </p>
                </template>
                <template slot="indemnityCoverage">
                    <therapist-indemnity-coverage :indemnityCoverage="indemnityCoverage"
                                                  @dateClicked="showDateDialog = true"
                                                  @regionClicked="showRegionDialog = true">
                    </therapist-indemnity-coverage>
                </template>
            </introduction>
          </div>
          <div class="col-md-5">
            <session-type-permissions
                :userBookingPermissions="userBookingPermissions"
                :userId="user_id"
                :userType="'therapist'"
                @refreshPage="getUserPermissions"
            >

            </session-type-permissions>
          </div>
          <div class="col-md-3">
            <session-package-permissions
                :userPackagePermissions="userPackagePermissions"
                :userId="user_id"
                :userType="'therapist'"
                @refreshPage="getUserPermissions"
            >

            </session-package-permissions>
          </div>
        </div>

        <div class="row">
          <div class="col-lg-4 col-md-4">
            <issues v-if="isIssuesLoaded"
                    :user_id="user_id"
                    :issues="issues"
                    :selectedIssues="selectedIssues.split(',').filter(function(i){return i != ''})"
                    v-on:updateIssues="updateIssues"
            ></issues>
          </div>
          <div class="col-lg-4 col-md-4">
            <treatments v-if="isIssuesLoaded"
                        :user_id="user_id"
                        :treatments="treatments"
                        :selectedTreatments="selectedTreatments.split(',').filter(function(i){return i != ''})"
                        v-on:updateTreatments="updateTreatments"
            ></treatments>
          </div>
          <div class="col-lg-4 col-md-4">
            <insurance-providers :user_id="user_id"
                                 :is-paid-type-editable="isPaidTypeEditable"
                                 :insurancePaidTypes="insurancePaidTypes"
                                 :insuranceProviders="insuranceProviders"
                                 :supportedInsuranceProvider="supportedInsuranceProviders"
                                 v-on:updateInsuranceProviders="updateInsuranceProviders"
                                 @refresh-insurance="getTherapistInsuranceProviders"
                                 :key="insuranceProviders"
            ></insurance-providers>
          </div>
        </div>

        <div class="row">
          <div class="col-12">
            <bookings :user_id="user_id"
                      :headerTitle="'Booking Activity'"
                      :isDetail="true"
                      @refreshBookings="refreshBookings"
                      userType="therapist"

            ></bookings>
          </div>
        </div>

        <div class="row">
          <div class="col-md-6">
            <availability></availability>
          </div>
          <div class="col-md-6">
            <clients :user_id="user_id"
                     :clients="connectedClients"
                     v-on:updateClients="updateClients"
            ></clients>
          </div>
        </div>

        <!-- Therapist Leave Modal -->

        <therapist-leave-modal v-if="showLeaveDialog"
                               :visible="showLeaveDialog"
                               :user-id="user_id"
                               :therapistLeave="therapistLeave"
                               @hide="hideTherapistAddLeave"
                               @refresh-leave="getTherapistLeave"
                               @hideTherapistAddLeave="hideTherapistAddLeave"
        >
        </therapist-leave-modal>

        <indemnity-coverage-date-modal v-if="showDateDialog"
                                       :visible="showDateDialog"
                                       :user-id="user_id"
                                       :expiry_date="indemnityCoverage.indemnity_insurance_expiry"
                                       @refreshIndemnity="getTherapistIndemnityCoverage"
                                       @hide="showDateDialog = false">
        </indemnity-coverage-date-modal>

        <indemnity-coverage-region-modal v-if="showRegionDialog"
                                         :visible="showRegionDialog"
                                         :user-id="user_id"
                                         :insurance_regions="indemnityCoverage.insurance_regions"
                                         @refreshIndemnity="getTherapistIndemnityCoverage"
                                         @hide="showRegionDialog = false">

        </indemnity-coverage-region-modal>



      </div>
    </v-layout>
  </v-container>
</template>

<script>

import Activity from '../../components/people/Activity.vue'
import Availability from '../../components/people/Availability.vue'
import Bookings from '../../components/main/Bookings.vue'
import Clients from '../../components/people/Clients.vue'
import InsuranceProviders from '../../components/people/InsuranceProviders.vue'
import Introduction from '../../components/people/Introduction.vue'
import Issues from '../../components/people/Issues.vue'
import Treatments from '../../components/people/Treatments.vue'
import TherapistEmail from '../../components/people/TherapistEmail'
import TherapistName from '../../components/people/TherapistName'
import TherapistDesignation from '../../components/people/TherapistDesignation'
import TherapistTitle from '../../components/people/TherapistTitle'
import TherapistBio from '../../components/people/TherapistBio'
import ProfilePicture from '../../components/main/ProfilePicture'
import TherapistGender from '../../components/people/TherapistGender'
import TherapistVideo from '../../components/people/therapist/TherapistVideo'
import TherapistVideoDuration from '../../components/people/therapist/TherapistVideoDuration'
import TherapistLeaveModal from '../../components/people/therapist/TherapistLeaveModal'
import TherapistXeroContact from "@/components/people/TherapistXeroContact";
import TherapistStandingPaymentId from '../../components/people/TherapistStandingPaymentId'
import TherapistLatestInvoice from '../../components/people/TherapistLatestInvoice'
import TherapistPayoutFee from "@/components/people/therapist/TherapistPayoutFee";
import IndemnityCoverageDateModal from '../../components/people/therapist/IndemnityCoverageDateModal'
import IndemnityCoverageRegionModal from '../../components/people/therapist/IndemnityCoverageRegionModal'
import TherapistIndemnityCoverage from '../../components/people/therapist/TherapistIndemnityCoverage'
import SessionTypePermissions from '../../components/people/sessionTypePermissions'
import sessionPackagePermissions from '../../components/people/sessionPackagePermissions'

export default {
  components: {
      SessionTypePermissions,
      sessionPackagePermissions,
      TherapistPayoutFee,
      TherapistXeroContact,
      TherapistVideo,
      ProfilePicture,
      Introduction,
      Activity,
      Issues,
      Treatments,
      InsuranceProviders,
      Bookings,
      Availability,
      Clients,
      TherapistEmail,
      TherapistName,
      TherapistDesignation,
      TherapistTitle,
      TherapistBio,
      TherapistGender,
      TherapistVideoDuration,
      TherapistLeaveModal,
      TherapistStandingPaymentId,
      TherapistLatestInvoice,
      IndemnityCoverageDateModal,
      IndemnityCoverageRegionModal,
      TherapistIndemnityCoverage,
  },
  props: {},
  data: () => ({
    isEverythingReady: true,
    page: 1,
    user_id: null,
    therapist: null,
    issues: [],
    treatments: [],
    insuranceProviders: [],
    insurancePaidTypes: [],
    activities: [],
    user_status: 1,
    showLeaveDialog: false,
    showDateDialog: false,
    showRegionDialog: false,
    therapistLeave: null,
    indemnityCoverage: null,
    isPaidTypeEditable: true,
    selectedIssues: null,
    selectedTreatments: null,
    isIssuesLoaded: false,
    connectedClients: [],
    supportedInsuranceProviders: [],
    areBookingsReady: false,
    userBookingPermissions: [],
    userPackagePermissions: []

  }),
  created() {
    this.user_id = this.$route.params.id;
    this.getUserPermissions();
    this.getTherapistInsurancePaidType();
    this.getTherapistIndemnityCoverage();
  },

  async mounted() {
    await this.getTherapistProfile();
    await this.getTherapistIssuesAndTreatments();
    await this.getTherapistInsuranceProviders();
    await this.getConnectedClients();
    await this.getTherapistLeave();
    await this.getUserPermissions();
  },

  methods: {
    async getTherapistProfile() {
      let loader = this.$loading.show()
      this.isEverythingReady = false;
      this.$http.get(this.url + "therapist/profile", {
        params: {
          therapist_id: this.user_id,
          timezone: this.timezone,
        }
      }).then(response => {
        this.therapist = response.data.data
        this.user_status = this.therapist.status === 'blocked' ? 0 : 1
        this.isPaidTypeEditable = this.therapist.fte !== 1;

      }).catch(error => {
        this.$toastr("error", error.response.data.message, "");
      }).finally(() => {
        loader.hide();
        this.isEverythingReady = true
      });
    },

    async getTherapistIssuesAndTreatments() {
      this.$http.get(this.url + "therapist/issues-and-treatments", {
        params: {
          therapist_id: this.user_id,
          timezone: this.timezone
        }
      }).then(response => {
        this.issues = response.data.issues;
        this.treatments = response.data.treatments;
        this.selectedIssues = response.data.therapist_issues;
        this.selectedTreatments = response.data.therapist_treatments;
        this.isIssuesLoaded = true;
      }).catch(error => {
        this.$toastr("error", error.response.data.message, "");
      })
    },

    async getTherapistInsuranceProviders () {
      this.$http.get(this.url + "therapist/insurance-providers", {
        params: {
          therapist_id: this.user_id,
          timezone: this.timezone
        }
      }).then(response => {
        this.insuranceProviders = response.data.insurance_providers
        this.supportedInsuranceProviders = response.data.supported_insurance_providers
      }).catch(error => {
        this.$toastr("error", error.response.data.message, "");
      })
    },

    getTherapistIndemnityCoverage() {
      this.$http.get(this.url + "therapist/indemnity-coverage", {
        params: {
          therapist_id: this.user_id,
          timezone: this.timezone
        }
      }).then(response => {
        this.indemnityCoverage = response.data.indemnity_coverage;

      }).catch(error => {
        this.$toastr("error", error.response.data.message, "");
      })
    },

     getTherapistInsurancePaidType() {
      this.$http.get(this.url + "therapist/insurance-paid-types", {
        params: {
          therapist_id: this.user_id,
          timezone: this.timezone
        }
      }).then(response => {
        this.insurancePaidTypes = response.data.insurance_paid_types
      }).catch(error => {
        this.$toastr("error", error.response.data.message, "");
      })
    },
    async getConnectedClients() {
      this.$http.get(this.url + "therapist/connected-clients", {
        params: {
          therapist_id: this.user_id,
          timezone: this.timezone
        }
      }).then(response => {
        this.connectedClients = response.data.data;
      }).catch(error => {
        this.$toastr("error", error.response.data.message, "");
      })
    },
       getUserPermissions () {
        this.$http.get(this.url + "booking-permissions", {
          params: {
            user_id: this.user_id,
            timezone: this.timezone
          }
        }).then(response => {
          this.userBookingPermissions = response.data.sessionTypes
          this.userPackagePermissions = response.data.sessionPackages
        }).catch(error => {
          this.$toastr("error", error.response.data.message, "")
        })
      },

    async getTherapistLeave() {
      this.$http.get(this.url + "therapist/leave", {
        params: {
          therapist_id: this.user_id,
          timezone: this.timezone
        }
      }).then(response => {
        this.therapistLeave = response.data.therapist_leave;
        this.showLeaveDialog = false;
      })
    },
    updateWelcomeVideoStatus() {
      let self = this
      let loader = this.$loading.show()
      this.$http.put(this.url + "therapist/video/update", {
        therapist_id: this.user_id,
        status: self.therapist.is_video_active
      }).then(response => {
        loader.hide()
        self.$toastr("success", response.data.message, "")
      }).catch(error => {
        loader.hide()
        self.$toastr("error", error.response.data.message, "")
      })
    },

    updateProfile(data, key) {
      let loader = this.$loading.show()
      const formData = new FormData()
      if (key === "name") {
        formData.append("first_name", data[0])
        formData.append("last_name", data[1])
      } else {
        formData.append(key, data)
      }
      formData.append("therapist_id", this.user_id)
      this.$http.post(this.url + "therapist/profile/update", formData).then(response => {
        loader.hide();
        this.updateSendBirdData();
        this.$toastr("success", response.data.message, "");
      }).catch(error => {
        loader.hide()
        this.$toastr("error", error.response.data.message, "");
      })
    },

    updatePsychiatristStatus() {
      let loader = this.$loading.show()
      this.$http.put(this.url + "therapist/is-psychiatrist/update", {
        therapist_id: this.user_id,
        status: this.therapist.is_physiatrist
      }).then(response=> {
        loader.hide();
        this.$toastr("success", response.data.message, "");
      }).catch(error => {
        loader.hide()
        this.$toastr("error", error.response.data.message, "");
      })
    },

    caseLoadFull() {
      let loader = this.$loading.show();
      this.$http.put(this.url + "therapist/case-load-full/update", {
        therapist_id: this.user_id,
        status: this.therapist.case_load_full
      }).then(response => {
        loader.hide();
        this.$toastr("success", response.data.message, "");
      }).catch(function (error) {
        loader.hide();
        this.$toastr("error", error.response.data.message, "");
      });
    },

    updateWelcomeVideo() {
      let loader = this.$loading.show()
      this.$http.put(this.url + "therapist/video/update", {
        therapist_id: this.user_id,
        status: this.therapist.is_video_active
      }).then(response => {
        loader.hide()
        this.$toastr("success", response.data.message, "");
      }).catch(function (error) {
        loader.hide()
        this.$toastr("error", error.response.data.message, "");
      })
    },

    updateFteStatus() {
      let loader = this.$loading.show();
      this.$http.put(this.url + "therapist/fte/update", {
        user_id: this.user_id,
        status: this.therapist.fte
      }).then(response => {
        loader.hide();
        this.getTherapistProfile();
        this.isPaidTypeEditable = this.therapist.fte !== 1;
        this.$toastr("success", response.data.message, "");
      }).catch(error => {
        loader.hide();
        this.$toastr("error", error.response.data.message, "");
      });
    },

    approveUser() {
      let loader = this.$loading.show();
      this.$http.put(this.url + "approve_user", {
        user_id: this.user_id
      }).then(response => {
        loader.hide()
        this.therapist.approval_status = "approved";
        this.$toastr("success", response.data.message, "");
      }).catch(function (error) {
        loader.hide()
        this.$toastr("error", error.response.data.message, "");
      })
    },


    updateSendBirdData() {
      const formData = new FormData()
      formData.append("user_id", this.user_id)
      this.$http.post(this.url + "update_send_bird_data", formData).then(response => {
        this.$toastr("success", response.data.message, "");
      }).catch((error) => {
        this.$toastr("error", error.response.data.message, "");
      })
    },

    toggleUpdated() {

      let loader = this.$loading.show()
      this.$http.put(this.url + "user_status", {
        user_id: this.user_id,
        status: this.user_status
      }).then(response => {
        loader.hide();
        this.$toastr("success", response.data.message, "");
      }).catch(error => {
        loader.hide();
        this.$toastr("error", error.response.data.message, "");
      })
    },


    updateTherapistProfile(value) {
      this.therapist = value
    },

    updateIssues(issues) {
      this.selectedIssues = issues;
    },
    updateTreatments(treatments) {
      this.selectedTreatments = treatments;
    },
    updateInsuranceProviders(data) {
      this.supportedInsuranceProviders = response.data.supported_insurance_providers
      this.insuranceProviders = data.insurance_providers;
    },
    updateClients(clients) {
      this.connectedClients = clients;
    },
    updateFee(fee) {
      this.therapist.fee = fee;
    },
    updateXeroContact(xeroContact) {
      this.therapist.xero_contact = xeroContact;
    },
    latestInvoiceUpdated(latestInvoice) {
      this.therapist.latest_invoice = latestInvoice;
    },
    standingPaymentIdUpdated(paymentId) {
      this.therapist.standing_payment_id = paymentId;
    },
    showTherapistAddLeaveDialog() {
      this.showLeaveDialog = true;
    },
    hideTherapistAddLeave() {
      this.showLeaveDialog = false;
    },

    refreshBookings () {
      this.getTherapistProfile()
    },
  }
}
</script>
<style>
.toggle-switch {
  display: flex;
  justify-content: space-between;
}
</style>
